import { useNotification } from '@bbnpm/bb-ui-framework';
import { node, object, oneOfType, string } from 'prop-types';
import { useState } from 'react';
import { useEffectOnce, useToggle } from 'react-use';

import { cookiesSupported } from '../utils';

const DISMISSAL_TIME = 10000;

const Alert = ({ children, variant, details }) => {
  const notifAccessor = useNotification();

  switch (variant) {
    case 'success':
      notifAccessor.addSuccess({
        message: children,
        timeout: DISMISSAL_TIME,
        details: details,
      });
      break;

    case 'danger':
      notifAccessor.addError({
        message: children,
        timeout: DISMISSAL_TIME,
        details: details,
      });
      break;

    default:
      break;
  }

  return null;
};

Alert.propTypes = { children: node, variant: string, details: string };

const Flash = props => {
  const [isCookiesSupported, toggleIsCookiesSupported] = useToggle(true);

  const [{ alert, notice }, setState] = useState(props);

  useEffectOnce(() => {
    const sessionFlash = window.sessionStorage.getItem('flash');
    if (sessionFlash) {
      window.sessionStorage.removeItem('flash');
      setState(state => ({ ...JSON.parse(sessionFlash), ...state }));
    }

    toggleIsCookiesSupported(cookiesSupported());
  });

  return (
    <>
      {notice && (
        <Alert details={notice.details} variant="success">
          {notice.message || notice}
        </Alert>
      )}
      {alert && (
        <Alert details={alert.details} variant="danger">
          {alert.message || alert}
        </Alert>
      )}
      {!isCookiesSupported && (
        <Alert variant="danger">
          <h2>
            This site requires browser cookies to be enabled. Refresh this page
            after cookies are enabled to begin
          </h2>
        </Alert>
      )}
    </>
  );
};

Flash.propTypes = {
  notice: oneOfType([string, object]),
  alert: oneOfType([string, object]),
};

export default Flash;
