import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';
import { useToggle } from 'react-use';

import { destroy } from '../../../api';

const DeleteCustomerModal = ({
  row,
  showModal,
  toggleShowModal,
  refreshData,
}) => {
  const handleDelete = async () => {
    try {
      await destroy(row.original.href);
      refreshData();
    } catch (error) {
      console.log('Here comes the error');
      console.log(error);
      alert('There were issues with your request, please try again.');
    }
    toggleShowModal();
  };
  return (
    <Modal
      portalContainerId="bfe-root"
      title="Delete Customer"
      isOpen={showModal}
      primaryActions={
        <>
          <Button kind="secondary" onClick={toggleShowModal}>
            Cancel
          </Button>
          <Button kind="destruction" onClick={handleDelete}>
            Delete
          </Button>
        </>
      }>
      <Typography.Text>Please click delete to confirm.</Typography.Text>
    </Modal>
  );
};

const DeleteCustomerCell = ({ row, refreshData }) => {
  const [showModal, toggleShowModal] = useToggle(false);

  return (
    <>
      <Button kind="destruction" icon="trash" onClick={toggleShowModal} />
      <DeleteCustomerModal
        row={row}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        refreshData={refreshData}
      />
    </>
  );
};

export default DeleteCustomerCell;
