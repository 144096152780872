import {
  Table as BBUITable,
  GlobalStyles,
  ThemeProvider,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import Button from '../../Button';
import CheckOrMinusCell from '../../CheckOrMinusCell';
import Container from '../../Container';
import PrimaryKey from '../../PrimaryKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import DeleteCustomerCell from '../customers/DeleteCustomerCell';
import { CellValueStringProps } from '../institutions/types';
import { ShowProps } from './types';

const Edit = ({ cell }) => (
  <span className="text-nowrap">
    <Button
      title="Edit"
      icon="edit"
      variant="light"
      size="sm"
      href={cell.value}
    />
  </span>
);

Edit.propTypes = CellValueStringProps;

const customerColumns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'BBG Cust ID', accessor: 'bbgCustomerId' },
  { Header: 'Location', accessor: 'location' },
  { Header: 'Billables', accessor: 'billables' },
  { Header: 'Terminals', accessor: 'terminals' },
  {
    Header: 'Terminal Access',
    accessor: 'terminalAccess',
    Cell: CheckOrMinusCell,
  },
  {
    id: 'actions',
    accessor: 'editAdminCustomerPath',
    disableSortBy: true,
    Cell: Edit,
  },
  {
    id: 'actionsDelete',
    accessor: 'id',
    disableSortBy: true,
    Cell: DeleteCustomerCell,
  },
];

const fetchCustomers = ({ totalCount, pageSize, customers }) => {
  return {
    data: customers,
    pageSize,
    totalCount,
  };
};

const institutionColumns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryKey },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Category', accessor: 'category' },
  {
    Header: 'Student Rate',
    accessor: 'studentRateEligible',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Case Studies',
    accessor: 'caseStudiesAccess',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Resource Center',
    accessor: 'professorToolkitAccess',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Unlimited BMC',
    accessor: 'unlimitedBmcAccess',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'BMC Limit',
    accessor: 'bmcAccessLimit',
  },
];

const Show = ({
  name,
  bbgFirmId,
  editAdminFirmPath,
  institutions,
  customers,
  adminCustomersPath,
  newAdminFirmCustomerPath,
}) => {
  const [{ refreshData }, setRefreshDataFn] = useState({
    refreshData: () => {},
  });
  const fetchData = useFetchData(adminCustomersPath, fetchCustomers);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <div className="d-flex align-items-center">
          <h1>{name}</h1>

          <Button
            className="ml-4"
            variant="outline-dark"
            href={editAdminFirmPath}
            size="sm">
            Edit
          </Button>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <BBUITable>
              <tbody>
                <tr>
                  <td className="text-muted text-right">BBG Firm ID</td>
                  <td>{bbgFirmId}</td>
                </tr>
              </tbody>
            </BBUITable>
          </div>
        </div>
        <h4 className="m-0">Institutions</h4>
        <Table initialData={institutions} columns={institutionColumns} />
        <div className="d-flex align-items-center border-top pt-3 my-3">
          <h4 className="m-0">Customers</h4>
          <Button
            href={newAdminFirmCustomerPath}
            className="ml-4"
            variant="outline-dark"
            size="sm">
            Add Customer
          </Button>
        </div>
        <Table
          initialData={customers}
          columns={customerColumns}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
        />
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
