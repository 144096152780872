import {
  Table as BBUITable,
  GlobalStyles,
  ThemeProvider,
} from '@bbnpm/bb-ui-framework';
import { useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useToggle } from 'react-use';

import { create, post } from '../../../api';
import { noop } from '../../../utils';
import Button from '../../Button';
import CheckOrMinusCell from '../../CheckOrMinusCell';
import CheckOrMinusIcon from '../../CheckOrMinusIcon';
import Container from '../../Container';
import DaperImg from '../../DaperImg';
import PrimaryKey from '../../PrimaryKey';
import Spinner from '../../Spinner';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import DeleteCustomerCell from '../customers/DeleteCustomerCell';
import AddFirmInstitutionModal from '../firm_institutions/AddFirmInstitutionModal';
import DeleteFirmInstitutionCell from '../firm_institutions/DeleteFirmInstitutionCell';
import { CellValueStringProps, ShowProps } from './types';

const firmColumns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryKey, disableSortBy: true },
  { Header: 'Name', accessor: 'name', disableSortBy: true },
  { Header: 'BBG Firm ID', accessor: 'bbgFirmId', disableSortBy: true },
  {
    accessor: 'id',
    id: 'actions',
    disableSortBy: true,
    Cell: DeleteFirmInstitutionCell,
  },
];

const firmsCb = ({ totalCount, pageSize, firmInstitutions }) => ({
  data: firmInstitutions.map(firmInstitution => ({
    adminHref: firmInstitution.adminHref,
    ...firmInstitution.firm,
  })),
  pageSize,
  totalCount,
});

const fetchCustomers = ({ totalCount, pageSize, customers }) => ({
  data: customers,
  pageSize,
  totalCount,
});

const EditCustomer = ({ cell }) => (
  <span className="text-nowrap">
    <Button
      title="Edit"
      icon="edit"
      variant="light"
      size="sm"
      href={cell.value}
    />
  </span>
);

EditCustomer.propTypes = CellValueStringProps;

const userCb = ({ totalCount, pageSize, users }) => ({
  data: users,
  pageSize,
  totalCount,
});

const userColumns = [
  { Header: 'ID', accessor: 'userid', filteredAs: 'number', Cell: PrimaryKey },
  { Header: 'UUID', accessor: 'uuid', filteredAs: 'number' },
  { Header: 'Type', accessor: 'type' },
  { Header: 'First Name', accessor: 'firstName', filteredAs: 'string' },
  { Header: 'Last Name', accessor: 'lastName', filteredAs: 'string' },
  { Header: 'Email', accessor: 'email', filteredAs: 'string' },
  { Header: 'Created', accessor: 'createdAt' },
];

const userScopes = [
  { id: 'isCandidate', title: 'Candidate' },
  { id: 'isHsStudent', title: 'High School' },
  { id: 'isProfessor', title: 'Professor' },
];

const Show = ({
  id,
  name,
  notes,
  studentRateEligible,
  caseStudiesAccess,
  professorToolkitAccess,
  unlimitedBmcAccess,
  editAdminInstitutionPath,
  emailDomains,
  firmInstitutions,
  customers,
  assignUsersPath,
  adminUsersPath,
  countryCode,
  sponsoredLogo,
  layoutTheme,
  category,
  createFirmInstitutionPath,
  adminFirmInstitutionsPath,
  adminCustomersPath,
}) => {
  const firms = firmInstitutions.map(firmInstitution => ({
    adminHref: firmInstitution.adminHref,
    ...firmInstitution.firm,
  }));
  const [usersAssigmentSubmitting, toggleUsersAssigmentSubmitting] =
    useToggle(false);
  const [usersAssigmentSubmitted, setUsersAssigmentSubmitted] = useState(false);
  const [showAddFirmModal, toggleShowAddFirmModal] = useToggle(false);
  const [errors, setErrors] = useState({});
  const [{ refreshData: refreshDataFirmFn }, setRefreshFirmDataFn] = useState({
    refreshData: noop,
  });
  const [{ refreshData: refreshDataCustFn }, setRefreshCustDataFn] = useState({
    refreshData: noop,
  });
  const fetchFirmsData = useFetchData(adminFirmInstitutionsPath, firmsCb);
  const fetchCustomersData = useFetchData(adminCustomersPath, fetchCustomers);

  const firmNameById = useMemo(
    () =>
      firms.reduce(
        (acc, firm) => ({
          ...acc,
          [firm.id]: firm.name,
        }),
        {},
      ),

    [firms],
  );

  const customerColumns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'BBG Cust ID', accessor: 'bbgCustomerId' },
      { Header: 'Location', accessor: 'location' },
      { Header: 'Billables', accessor: 'billables' },
      { Header: 'Total Terminals', accessor: 'terminals' },
      {
        Header: 'Terminal Access',
        accessor: 'terminalAccess',
        Cell: CheckOrMinusCell,
      },
      {
        Header: 'Firm Name',
        id: 'firmName',
        accessor: ({ firmId }) => firmNameById[firmId],
      },
      {
        id: 'actionsEdit',
        accessor: 'editAdminCustomerPath',
        disableSortBy: true,
        Cell: EditCustomer,
      },
      {
        id: 'actionsDelete',
        accessor: 'id',
        disableSortBy: true,
        Cell: DeleteCustomerCell,
      },
    ],
    [firmNameById],
  );

  const fetchUsersData = useFetchData(adminUsersPath, userCb);

  const handleUsersAssigment = async e => {
    e.preventDefault();
    toggleUsersAssigmentSubmitting();
    try {
      await post(assignUsersPath);
      setUsersAssigmentSubmitted(true);
    } catch (error) {
      alert('Unexpected error occurred during users assigment');
    }
    toggleUsersAssigmentSubmitting();
  };

  const handleAddFirmSubmit = async values => {
    await create(createFirmInstitutionPath, values)
      .then(() => {
        refreshDataFirmFn();
        toggleShowAddFirmModal();
      })
      .catch(error => {
        setErrors(error.response.data.errors);
      });
  };

  useEffect(() => {
    setErrors({});
  }, [showAddFirmModal]);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <AddFirmInstitutionModal
          isOpen={showAddFirmModal}
          toggleIsOpen={toggleShowAddFirmModal}
          newMembersPath={createFirmInstitutionPath}
          institutionId={id}
          onSubmit={handleAddFirmSubmit}
          errors={errors}
        />
        {usersAssigmentSubmitted && (
          <Alert variant="success">
            Your request has been entered for processing. An email will be sent
            upon completion.
          </Alert>
        )}
        <div className="d-flex align-items-center">
          <h1>{name}</h1>
          <Button
            className="ml-4"
            variant="outline-dark"
            href={editAdminInstitutionPath}
            size="sm">
            Edit Institution
          </Button>
          <Button
            disabled={usersAssigmentSubmitting}
            className="ml-4"
            variant="outline-dark"
            size="sm"
            onClick={handleUsersAssigment}>
            {usersAssigmentSubmitting && <Spinner />}
            Assign institution to users
          </Button>
        </div>
        <h4>
          Category: <span className="font-weight-normal">{category}</span>
        </h4>
        {notes && <p className="text-pre-wrap">{notes}</p>}
        <h4 className="border-top pt-3 mt-3">Email Domains</h4>
        <BBUITable>
          <thead>
            <tr>
              <th>Domain</th>
            </tr>
          </thead>
          <tbody>
            {emailDomains.map(({ id, domain }) => (
              <tr key={id}>
                <td>@{domain}</td>
              </tr>
            ))}
          </tbody>
        </BBUITable>

        <h4 className="border-top pt-3 mt-3">Sponsored Layout Theme</h4>
        {layoutTheme && <p>{layoutTheme.name}</p>}

        <h4 className="border-top pt-3 mt-3">Sponsored Logo</h4>
        {sponsoredLogo && (
          <div>
            <DaperImg metadata={sponsoredLogo} height={150} />
          </div>
        )}

        <h4 className="border-top pt-3 mt-3">Account Information</h4>
        <div className="d-flex justify-content-between">
          {[
            [
              ['ID', id],
              [
                'Student Rate',
                <CheckOrMinusIcon
                  key="studentRateEligible"
                  isTrue={studentRateEligible}
                />,
              ],
            ],
            [
              [
                'Case Studies',
                <CheckOrMinusIcon
                  key="caseStudiesAccess"
                  isTrue={caseStudiesAccess}
                />,
              ],
              [
                'Country',
                countryCode
                  ? `${countryCode.countryName} (${countryCode.region})`
                  : '',
              ],
            ],
            [
              [
                'Unlimited BMC',
                <CheckOrMinusIcon
                  key="unlimitedBmcAccess"
                  isTrue={unlimitedBmcAccess}
                />,
              ],
              [
                'Resource Center',
                <CheckOrMinusIcon
                  key="professorToolkitAccess"
                  isTrue={professorToolkitAccess}
                />,
              ],
            ],
          ].map((table, i) => (
            <div key={i}>
              <BBUITable>
                <tbody>
                  {table.map(([k, v], j) => (
                    <tr key={j}>
                      <td className="text-muted text-right">{k}</td>
                      <td>{v}</td>
                    </tr>
                  ))}
                </tbody>
              </BBUITable>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center border-top pt-3 my-3">
          <h4 className="m-0">Firms</h4>
          <Button
            onClick={toggleShowAddFirmModal}
            className="ml-4"
            variant="outline-dark"
            size="sm">
            Link to Firm
          </Button>
        </div>
        <Table
          columns={firmColumns}
          initialData={firms}
          refreshData={refreshDataFirmFn}
          onRefreshDataFn={setRefreshFirmDataFn}
          fetchData={fetchFirmsData}
        />

        <h4 className="border-top pt-3 my-3">Customers</h4>
        <Table
          columns={customerColumns}
          initialData={customers}
          refreshData={refreshDataCustFn}
          onRefreshDataFn={setRefreshCustDataFn}
          fetchData={fetchCustomersData}
        />

        <h4 className="border-top pt-3 my-3">Users</h4>
        <Table
          columns={userColumns}
          initialScopes={{ isCandidate: false, isProfessor: false }}
          availableScopes={userScopes}
          fetchData={fetchUsersData}
        />
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
